.loading {
  display: flex;  
  justify-content: center;  
  align-items: center;   
  text-align: center;    
  padding: 50px;
  width: 100%;
  height: 100vh;
  background-color:whitesmoke;  
}

.App {
  display: flex;  
  width: 100vw;
  height: 100vh;
  flex-direction: column;  
  justify-content: space-between;  
  background-color: whitesmoke;  
}

.main-menu {  
  display: flex;    
  padding: 10px;
  max-height: 70vh;
  flex-grow: 1;   
  align-items: center;  
}

.pin-menu {  
  width: 100vw;   
  display: flex;    
  padding: 10px;
  flex-grow: 1;   
  align-items: center;  
}

.widget-menu {
  display: flex;  
  flex-grow: 1;  
  flex-direction: column;
  align-items: center;   
  text-align: center;  
  justify-content: space-between;  
}

.button-menu {    
  display: flex;  
  padding: 2vmin;
  width: 50vw;
  align-items: center;   
  text-align: center;  
  justify-content: center;  
}

.button-menu-adm {    
  display: flex;  
  padding: 1vmin;
  width: 50vw;
}

.bottom-menu {    
  display: flex;  
  justify-content: center;  
  width: 100vw;
}

.logo-menu {     
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center;   
  text-align: center;    
  width: 100%;  
  height: 100%;
  padding: 5%;  
}

.left-menu {     
  display: flex;  
  flex-direction: column;
  justify-content: space-between;
  align-items: center;   
  text-align: center;    
  width: 100%;  
  height: 100%;
}

.scan-logo {  
  background: rgb(16,158,154);
  width: 30vmin;
  padding: 10px;
  margin: 20px;
}

.qr-download {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.qr-download-image {  
  margin-top: 40px;
  margin-bottom: 20px;
  width: 30vmin;  
}

.ilock-app-logo {  
  width: 70vmin;
}

.ilock-app-logo-mini {  
  width: 40vmin;
}

.door-selector {  
  display: flex;      
  align-items: center;   
  justify-content: center;
  padding: 20px; 
}

.no-focus-button {  
  outline: none !important;      
}

.ilock-version {
  display: flex;
  justify-content: space-between;
  padding: 2vmin;
  font-size: 2vmin;    
}

.info-widget {    
  max-width: 95vw;  
  padding: 10px;  
}

.center-widget {    
  display: flex;    
  flex-direction: column;
  align-items: center;  
  text-align: center;  
  justify-content: space-between; 
}

.card-widget {    
  display: flex;    
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.close-door-icon {
  height: 25vmin;
  pointer-events: none;
  padding: 5px;
}

@media all and (orientation:portrait) {
  .App {    
    justify-content: flex-start;      
  }

  .main-menu {    
    width: 100vw;    
    max-height: none;
    flex-direction: column;  
    justify-content: space-between;    
  }

  .button-menu {    
    display: flex;  
    padding: 2vmin;
    width: 80vw;
  }

  .button-menu-adm {    
    display: flex;  
    padding: 1vmin;
    width: 80vw;
  }

  .qr-download {     
    margin-top: 50px;
  }

  .pin-menu { 
    max-height: none;
    flex-direction: column;  
    justify-content: space-between;     
  }
}
@media all and (orientation:landscape) {
  /* Styles for Landscape screen */
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}